import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PolicyIcon from '@mui/icons-material/Policy';
import AppsIcon from '@mui/icons-material/Apps';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import { UserContext } from './auth';
import ChangePassword from './ChangePassword';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);


export default function MenuAppBar({ userData }) {
  const { userSignOut } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] =  React.useState(null);
  const [selectedListItem, setSelectedListItem] = React.useState('');
  const [openCPDialog, setOpenCPDialod] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleSignOut = () => {
    setAnchorEl(null);
    userSignOut();
  };

  const setChangePasswordDialog = (value) => {
    setOpenCPDialod(value);
    if (value) setAnchorEl(null);
  };

  return (
    <>
      {
        openCPDialog ? (
          <ChangePassword
            open={openCPDialog}
            setOpen={setChangePasswordDialog}
            username={userData ? userData.username : ''}
          />
        ) : null
      }
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              { selectedListItem }
            </Typography>
            <div style={{marginLeft: 'auto'}}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => setChangePasswordDialog(true)}>
                Change Password
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleSignOut}>
                Sign Out
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <MiniDrawer
          open={open}
          setOpen={setOpen}
          selectedListItem={selectedListItem}
          setSelectedListItem={setSelectedListItem}
        />
      </Box>
    </>
  );
}

MenuAppBar.defaultProps = {
  userData: null
};

MenuAppBar.propTypes = {
  userData: PropTypes.object
};


function MiniDrawer({ open, setOpen, selectedListItem, setSelectedListItem }) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleItemSelect = (text) => {
    setSelectedListItem(text);
    navigate(`/${text.toLowerCase().replace(/\s/g, '')}`);
  };

  const setPath = (pathname) => {
    switch (pathname) {
    case '/':
      setSelectedListItem('Dashboard');
      navigate('dashboard');
      break;
    case '/dashboard':
      setSelectedListItem('Dashboard');
      break;
    case '/devices':
      setSelectedListItem('Devices');
      break;
    case '/policies':
      setSelectedListItem('Policies');
      break;
    case '/mobilitygateway':
      setSelectedListItem('Mobility Gateway');
      break;
    case '/users':
      setSelectedListItem('Users');
      break;
    case '/applications':
      setSelectedListItem('Applications');
      break;
    default:
      // TODO what to do?
    }
  };

  useEffect(() => {
    setPath(`/${location.pathname.split('/')[1]}`);
  }, [location.pathname]);

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {['Dashboard', 'Devices', 'Policies', 'Mobility Gateway', 'Users', 'Applications'].map((text, index) => (
          <ListItem
            hover={text}
            key={text}
            disablePadding
            sx={{ display: 'block' }}
            component={Link}
            to={`/${text}`}
            onClick={() => handleItemSelect(text) }
          >
            <ListItemButton
              selected={selectedListItem === text}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center'
                }}
              >
                <GetIcon idx={index} />
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

MiniDrawer.defaultProps = {
  selectedListItem: ''
};

MiniDrawer.propTypes = {
  selectedListItem: PropTypes.string,
  setSelectedListItem: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};


function GetIcon({ idx }) {
  const icon = () => {
    switch (idx) {
    case 0: return <DashboardIcon />;
    case 1: return <PhonelinkSetupIcon />;
    case 2: return <PolicyIcon />;
    case 3: return <VpnLockIcon />;
    case 4: return <GroupIcon />;
    case 5: return <AppsIcon />;
    default: return null;
    }
  };

  return (
    <>
      {' '}
      {icon()}
      {' '}
    </>
  );
}

GetIcon.defaultProps = {
  idx: 0
};

GetIcon.propTypes = {
  idx: PropTypes.number
};
