import axios from 'axios';
import SnackbarService from './SnackbarService';
import { getAuthHeader, getUserData } from './auth';
import { createAlertMsg  } from './utils';


export const backendURL =  process.env.NODE_ENV === 'development' ?
  'http://localhost:3000' : window.location.origin;

export const apiURL = `${backendURL}/api`;
export const api = axios.create({baseURL: apiURL, withCredentials: true});


// This is provided to update access token if it is expired. This will apply
// to all https requests made by axios.
//
// Steps:
// 1. Checking if the error status is 401 (authorization failed).
// 2. If there is a valid refresh token: use it to get the access token.
// 3. if there is no valid refresh token: log the user out and return.
// 4. Redo the request again with the new token.
//
// TODO: Make sure to check for infinite loop condition if the refresh token
// API gives a 401. You can maintain a counter on every refresh token call.
// If the number exceeds, stop the stream. Then do any error handling on it,
// For example, showing a message that there was an error, and logout the user.
//
// Any status code that lie within the range of 2xx cause the
// (response) => {...} to trigger.
//
// Any status codes that falls outside the range of 2xx cause the
// (error) => {...} to trigger.
api.interceptors.response.use(
  (response) => response,
  (error) => new Promise((resolve, reject) => {
    const originalRequest = error.config;
    const userData = getUserData();
    if (error.response && error.response.status === 401 &&
        originalRequest && userData.refresh_token) {
      api.post(`/user/${userData.username}/update-access-token`, {
        _id: userData._id,
        refresh_token: userData.refresh_token
      })
        .then((response) => {
          localStorage.setItem('user', JSON.stringify(response.data));
          originalRequest.headers.authorization = response.data.access_token;
          resolve(api(originalRequest));
        })
        .catch((pError) => {
          reject(pError);
        });
    } else {
      reject(error);
    }
  })
);


export class API {
  static async fetch(route, setData) {
    try {
      const rv = await api.get(route, getAuthHeader());
      if (rv.data) setData(rv.data);
      return rv.data;
    } catch (error) {
      const errorMsg = createAlertMsg('Error while fetching data', error);
      SnackbarService.show(errorMsg, 'error');
    }
    return [];
  }

  static async insert(route, data, currentData, setData) {
    try {
      const rv = await api.post(route, data, getAuthHeader());
      if (rv.data) setData([...currentData, rv.data]);
    } catch (error) {
      const errorMsg = createAlertMsg('Error while adding new data', error);
      SnackbarService.show(errorMsg, 'error');
    }
  }

  static async update(route, update, idx = 0, currentData = [], setData = () => {}) {
    try {
      const rv = await api.post(route, update, getAuthHeader());
      if (rv.data) {
        const newData = [...currentData];
        newData[idx] = rv.data;
        setData(newData);
      }
    } catch (error) {
      const errorMsg = createAlertMsg('Error while updating the data', error);
      SnackbarService.show(errorMsg, 'error');
    }
  }

  static async delete(route, idx, currentData, setData) {
    try {
      await api.delete(route, getAuthHeader());
      if (idx >= 0) {
        const newData = [...currentData];
        newData.splice(idx, 1);
        setData(newData);
      }
    } catch (error) {
      const errorMsg = createAlertMsg('Error while deleting the item', error);
      SnackbarService.show(errorMsg, 'error');
    }
  }
}

