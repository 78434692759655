import * as React from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SnackbarService from './SnackbarService';
import { api, API } from './api';
import { getAuthHeader } from './auth';
import { createAlertMsg  } from './utils';
import MuiTableHead from './Table';
import { NameField, DescriptionField, DeleteTableItem, SetIPAddr } from './CommonComp';


export default function DevicesView() {
  const apiRoute = '/device';
  const [devices, setDevices] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  async function refreshTable() {
    await API.fetch(apiRoute, setDevices);
  }

  async function insertDevice(data) {
    await API.insert(apiRoute, data, devices, setDevices);
  }

  async function updateDevice(idx, update) {
    await API.update(`${apiRoute}/${devices[idx]._id}`, update, idx, devices, setDevices);
  }

  async function deleteDevice(idx) {
    await API.delete(`${apiRoute}/${devices[idx]._id}`, idx, devices, setDevices);
  }

  React.useEffect(() => {
    const asyncFn = async () => { await API.fetch(apiRoute, setDevices); };
    asyncFn();
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: '90px' }}
    >
      <Grid item xs={12}>
        {
          devices.length ? <DevTable data={devices} deleteDevice={deleteDevice} updateDevice={updateDevice} refreshTable={refreshTable} /> :
            <Grid container justifyContent="center" style={{ marginTop: '90px' }}>
              <Typography variant="h5" gutterBottom>
                No devices have been added yet.
              </Typography>
            </Grid>
        }
      </Grid>
      <Fab
        style={{ position: 'fixed', bottom: '21px', right: '15px' }}
        color='primary'
        aria-label='add'
        label='Add'
        onClick={() => setOpen(true) }
      >
        <AddIcon />
      </Fab>
      {
        open ? <SetDevice open={open} setOpen={setOpen} submit={insertDevice}/> : null
      }
    </Grid>
  );
}


function DevTable({ data, deleteDevice, updateDevice, refreshTable }) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <MuiTableHead columns={[
            'No.', 'Name', 'Device Type', 'Description', 'Policy', 'Provisioning', 'Settings', 'Actions'
          ]}/>
          <TableBody>
            {data.map((row, idx) => (
              <TableRow
                key={idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {idx + 1}
                </TableCell>
                <TableCell align="left">
                  {row.name}
                </TableCell>
                <TableCell align="left">
                  {row.type}
                </TableCell>
                <TableCell align="left">
                  {row.description}
                </TableCell>
                <TableCell align="left">
                  {row.policy_name}
                </TableCell>
                <TableCell align="center">
                  {
                    <RenderQRCodeGen data={row} refreshTable={refreshTable} />
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    <RenderSettings row={row} index={idx} submit={updateDevice} />
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    <DeleteTableItem index={idx} submit={deleteDevice} />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br/>
      <br/>
    </>
  );
}

DevTable.propTypes = {
  data: PropTypes.array.isRequired,
  deleteDevice: PropTypes.func.isRequired,
  updateDevice: PropTypes.func.isRequired,
  refreshTable: PropTypes.func.isRequired
};


function RenderQRCodeGen({ data, refreshTable }) {
  const keyGenerated = data.api_key === 'generated';
  const [warning, setWarning] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [qrcodeData, setQrCodeData] = React.useState(null);

  async function getQRCodeData() {
    try {
      const rv = await api.post('/provisioning', {device_id: data._id}, getAuthHeader());
      if (rv.data) setQrCodeData(rv.data);
    } catch (error) {
      SnackbarService.show(createAlertMsg('Error while fetching QR code data', error), 'error');
    }
  }

  const generateQRCode = async () => {
    setOpen(true);
    if (keyGenerated) setWarning(true);
    await getQRCodeData();
    await refreshTable();
  };

  return (
    <>
      <Button variant="outlined" onClick={generateQRCode}>
        {
          keyGenerated ? 'Re-Provision Device' : 'Provision Device'
        }
      </Button>
      {
        open ? (
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="qrcode-dialog"
            fullWidth
          >
            <DialogTitle id="qrcode-dialog-title" onClose={() => setOpen(false)}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                Provisioning by QR Code
                <IconButton aria-label="close" sx={{ ml: 'auto' }} onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
              <Typography variant="h6" gutterBottom>
                {
                  warning ? 'WARNING: Your previous key has been revoked.' : ''
                }
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Please open the Hydra app and scan the QR code below to provision the device.
              </Typography>
              <br/>
              <Grid container justifyContent="center">
                {
                  qrcodeData ? (
                    <QRCodeSVG value={JSON.stringify(qrcodeData)} size={256} />
                  ) : null
                }
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpen(false)}
                color="primary"
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        ) : null
      }
    </>
  );
}

RenderQRCodeGen.propTypes = {
  data: PropTypes.object.isRequired,
  refreshTable: PropTypes.func.isRequired
};


function RenderSettings({ row, index, submit }) {
  const [open, setOpen] = React.useState(false);

  const handleUpdate = async (update) => {
    setOpen(false);
    const {_id, ...rest} = update;
    await submit(index, rest);
  };

  return (
    <>
      <IconButton name="details" onClick={() => setOpen(true)}>
        <SettingsIcon />
      </IconButton>
      {
        open ? <SetDevice open={open} setOpen={setOpen} row={row} submit={handleUpdate}/> : null
      }
    </>
  );
}

RenderSettings.defaultProps = {
  index: 0
};

RenderSettings.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number,
  submit: PropTypes.func.isRequired
};


function SetDevice({ open, setOpen, row, submit }) {
  const [policies, setPolicies] = React.useState([]);
  const [data, setData] = React.useState(row || {
    name: '',
    type: 'Android',
    description: '',
    ipv4: ''
  });

  React.useEffect(() => {
    const asyncFn = async () => { await API.fetch('/policy', setPolicies); };
    asyncFn();
  }, []);


  const disableSaveBtn = () => {
    if (data.name.trim() === '') return true;
    if (row) return JSON.stringify(data) === JSON.stringify(row);
    return false;
  };

  const handleSaveBtn = async () => {
    setOpen(false);
    await submit(data);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="add-a-new-device-dialog"
        fullWidth
      >
        <DialogTitle id="add-new-device-dialog-title" onClose={() => setOpen(false)}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            { row ? 'Update The Device' : 'Add a New Device' }
            <IconButton aria-label="close" sx={{ ml: 'auto' }} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DeviceType data={data} setData={setData} />
            </Grid>
            <Grid item sm={12}>
              <NameField data={data} setData={setData} />
            </Grid>
            <Grid item sm={12}>
              <DescriptionField data={data} setData={setData} />
            </Grid>
            <Grid item xs={12}>
              {
                policies.length ? (<DevicePolicy policies={policies} data={data} setData={setData} />) : null
              }
            </Grid>
            <Grid item xs={12}>
              <SetIPAddr type='ipv4' data={data} setData={setData} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={0 || disableSaveBtn()}
            onClick={handleSaveBtn}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SetDevice.defaultProps = {
  row: undefined
};

SetDevice.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  row: PropTypes.object,
  submit: PropTypes.func.isRequired
};


function DeviceType({ data, setData }) {
  const [types] = React.useState(['Android', 'iOS', 'IoT']);

  return (
    <FormControl fullWidth>
      <InputLabel id="device-type">
        Device Type
      </InputLabel>
      <Select
        labelId="device-type-label"
        id="devicetype"
        value={data.type || ''}
        defaultValue={data.type}
        label="Select Device"
        onChange={(event) => setData({...data, type: String(event.target.value).trim()})}
      >
        {
          types.map((t) => (
            <MenuItem
              key={t}
              value={t}
            >
              {`${t}`}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

DeviceType.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired
};


function DevicePolicy({ policies, data, setData }) {
  const [value, setValue] = React.useState(() => {
    const found = policies.find((e) => e._id === data.policy_id);
    if (found) return found;
    return {};
  });

  const setPolicy = (v) => {
    setValue(v);
    setData({...data, policy_id: v._id, policy_name: v.name});
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="select-device-category">
        Select Policy
      </InputLabel>
      <Select
        labelId="select-device-category"
        id="selectdevicepolicy"
        label="Select Policy"
        value={value}
        onChange={(event) => setPolicy(event.target.value)}
      >
        {
          policies.map((p) => (
            <MenuItem
              key={p._id}
              value={p}
            >
              {`${p.name}`}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

DevicePolicy.propTypes = {
  policies: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired
};
