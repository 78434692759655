import * as React from 'react';
import PropTypes from 'prop-types';
import { experimentalStyled as styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { API } from './api';
import { appList, categoryList } from './constants';
import { deepCopy } from './utils';
import { DeleteTableItem, DescriptionField } from './CommonComp';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#BDEDFF',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary
}));


export default function AppsAndCategoriesView() {
  const apiRoute = '/app_category';
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  async function insertData(newData) {
    await API.insert(apiRoute, newData, data, setData);
  }

  async function updateData(idx, update) {
    // TODO. Check if data with th same id (data.facts.id) does not exists in the list.
    await API.update(`${apiRoute}/${data[idx]._id}`, update, idx, data, setData);
  }

  async function deleteData(idx) {
    await API.delete(`${apiRoute}/${data[idx]._id}`, idx, data, setData);
  }

  React.useEffect(() => {
    const asyncFn = async () => { await API.fetch(apiRoute, setData); };
    asyncFn();
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: '90px' }}
    >
      <Grid container spacing={3}>
        {
          data.length ? (
            data.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Item>
                  <AppGrid item={item} index={index} deleteData={deleteData} updateData={updateData} />
                </Item>
              </Grid>
            ))
          ) :
            <Grid container justifyContent="center" style={{ marginTop: '90px' }}>
              <Typography variant="h5" gutterBottom>
                No apps or categories have been added yet.
              </Typography>
            </Grid>
        }
      </Grid>
      <Grid item>
        <br/>
      </Grid>
      <Fab
        style={{ position: 'fixed', bottom: '21px', right: '15px' }}
        color='primary'
        aria-label='add'
        label='Add'
        onClick={() => setOpen(true) }
      >
        <AddIcon />
      </Fab>
      {
        open ? <SetApp open={open} setOpen={setOpen} submit={insertData}/> : null
      }
    </Grid>
  );
}


function AppGrid({ item, index, deleteData, updateData }) {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Avatar>
          {
            `${item.type.charAt(0)}`
          }
        </Avatar>
      </Grid>
      <Grid item>
        <Typography gutterBottom variant="h6">
          { item.fact.name }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle1">
          { item.description }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item>
        <RenderSettings row={item} index={index} submit={updateData} />
      </Grid>
      <Grid item>
        <DeleteTableItem index={index} submit={deleteData} />
      </Grid>
    </Grid>
  );
}

AppGrid.defaultProps = {
  index: 0
};

AppGrid.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object.isRequired,
  deleteData: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired
};


function RenderSettings({ row, index, submit }) {
  const [open, setOpen] = React.useState(false);

  const handleUpdate = async (update) => {
    setOpen(false);
    const {_id, ...rest} = update;
    await submit(index, rest);
  };

  return (
    <>
      <IconButton name="details" onClick={() => setOpen(true)}>
        <EditIcon />
      </IconButton>
      {
        open ? <SetApp open={open} setOpen={setOpen} row={row} submit={handleUpdate}/> : null
      }
    </>
  );
}

RenderSettings.defaultProps = {
  index: 0
};

RenderSettings.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number,
  submit: PropTypes.func.isRequired
};


function SetApp({ open, setOpen, row, submit }) {
  const [data, setData] = React.useState(deepCopy(row) || {
    fact: {id: '', name: ''},
    description: '',
    type: 'Application'
  });

  const disableSaveBtn = () => {
    if (data.fact.id === '' || data.fact.name === '') return true;
    if (row) return JSON.stringify(data) === JSON.stringify(row);
    return false;
  };

  const handleSaveBtn = async () => {
    setOpen(false);
    await submit(data);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="add-a-new-user-dialog"
        fullWidth
      >
        <DialogTitle id="add-new-user-dialog-title" onClose={() => setOpen(false)}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            { row ? 'Update Data' : 'Add a New App or Category' }
            <IconButton aria-label="close" sx={{ ml: 'auto' }} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DataType data={data} setData={setData} />
            </Grid>
            <Grid item sm={12}>
              <FactType data={data} setData={setData} />
            </Grid>
            <Grid item sm={12}>
              <DescriptionField data={data} setData={setData} required={false} />
            </Grid>
            <Grid item sm={12}>
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={0 || disableSaveBtn()}
            onClick={handleSaveBtn}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SetApp.defaultProps = {
  row: undefined
};

SetApp.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  row: PropTypes.object,
  submit: PropTypes.func.isRequired
};


function DataType({ data, setData }) {
  const [roles] = React.useState(['Application', 'Category']);

  return (
    <TextField
      fullWidth
      value={data.type || ''}
      defaultValue={data.type}
      onChange={(event) => setData({...data, type: String(event.target.value).trim(), fact: {id: '', name: ''}})}
      select
      label='Select Type'
    >
      {
        roles.map((t) => (
          <MenuItem
            key={t}
            value={t}
          >
            {`${t}`}
          </MenuItem>
        ))
      }
    </TextField>
  );
}

DataType.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired
};


function FactType({ data, setData }) {
  const setValue = (value) => {
    const fact = data.fact;
    fact.id = value;
    fact.name = categoryList[value] || appList[value];
    setData({...data, fact});
  };

  return (
    <TextField
      fullWidth
      value={data.fact.id || ''}
      defaultValue={data.fact.id}
      onChange={(event) => setValue(String(event.target.value).trim())}
      select
      label={data.type === 'Application' ? 'Select App' : 'Select Category'}
    >
      {
        Object.keys(data.type === 'Application' ? appList : categoryList).map((t) => (
          <MenuItem
            key={t}
            value={t}
          >
            {`${categoryList[t] || appList[t]}`}
          </MenuItem>
        ))
      }
    </TextField>
  );
}

FactType.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired
};
