import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { API } from '../api';
import { DragAndDropNetworkList } from './Common';
import { appList } from '../constants';


export default function AppPolicyRule({ data }) {
  const [apps, setApps] = React.useState([]);

  React.useEffect(() => {
    const asyncFn = async () => {
      const rv = await API.fetch('/app_category', () => {});
      if (rv) setApps(rv.filter((e) => e.type === 'Application'));
    };
    asyncFn();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectApp data={data} categories={apps} />
      </Grid>
      <DragAndDropNetworkList data={data} />
    </Grid>
  );
}

AppPolicyRule.propTypes = {
  data: PropTypes.object.isRequired
};


function SelectApp({ data, categories }) {
  const [value, setValue] = React.useState(data.getCategory());

  const setPolicyType = (newValue) => {
    data.setCategory(newValue, categories[0] ? categories[0].type : '');
    setValue(data.getCategory());
  };

  return (
    <TextField
      fullWidth
      value={value}
      defaultValue={value}
      onChange={(event) => setPolicyType(event.target.value)}
      select
      label='Application'
    >
      {
        categories.map((e) => (
          <MenuItem
            key={e.fact.id}
            value={e.fact.id}
          >
            {appList[e.fact.id]}
          </MenuItem>
        ))
      }
    </TextField>
  );
}

SelectApp.propTypes = {
  data: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired
};

