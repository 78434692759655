import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { api } from './api';
import { authSignOutHeader, getUserData, UserContext } from './auth';
import App from './App';
import theme from './theme';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

/*
 * This component is the main entry point to the entire React application. We
 * perform initialization here and setup various middlewares and contexts that
 * need to be available across the entire application.
 */
function Main() {
  const [userSignedIn, setUserSignedIn] = useState(() => {
    const data = getUserData();
    if (data && data.username) return data.username;
    return null;
  });

  const userSignIn = (data) => {
    localStorage.setItem('user', JSON.stringify(data));
    setUserSignedIn(data.username);
  };

  const userSignOut = async () => {
    const data = getUserData();
    await api.get(`/user/${data.username}/sign-out`, authSignOutHeader(data));
    setUserSignedIn(null);
    localStorage.setItem('user', null);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <UserContext.Provider value={{userSignedIn, userSignIn, userSignOut}}>
            <App />
          </UserContext.Provider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

root.render(<Main />);
