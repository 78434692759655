import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { experimentalStyled as styled } from '@mui/material/styles';
import { API } from './api';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#BDEDFF',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary
}));


export default function DashboardView() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      sx={{ marginTop: '90px' }}
    >
      <Grid item xs={12} >
        <Typography variant="h6" gutterBottom>
          Hi, Welcome back!
        </Typography>
      </Grid>
      <Grid item xs={12} >
        <br />
      </Grid>
      <UpperLayerDashboard />
    </Grid>
  );
}

function UpperLayerDashboard() {
  const navigate = useNavigate();
  const [devices, setDevices] = React.useState([]);
  const [gateways, setGateways] = React.useState([]);
  const [policies, setPolicies] = React.useState([]);

  React.useEffect(() => {
    const asyncFn = async () => {
      await API.fetch('/device', setDevices);
      await API.fetch('/policy', setPolicies);
      await API.fetch('/gateway', setGateways);
    };
    asyncFn();
  }, []);

  return (
    <Grid container spacing={3} columns={20}>
      <Grid item xs={6}>
        <Item>
          <DeviceDashboard data={devices} navigate={navigate} />
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <PolicyDashboard data={policies} devices={devices} navigate={navigate} />
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <GatewayDashboard data={gateways} navigate={navigate} />
        </Item>
      </Grid>
    </Grid>
  );
}


function DeviceDashboard({ data, navigate }) {
  const getProvisionedDevs = () => {
    let count = 0;
    data.forEach((element) => {
      if (element.api_key === 'generated') count += 1;
    });
    return count;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} >
        <DashboardCardName name='Devices' link='/devices' navigate={navigate} />
      </Grid>
      <Grid item xs={12} >
        <Typography variant="h2" gutterBottom>
          {data.length}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Provisioned:
          {' '}
          {getProvisionedDevs()}
        </Typography>
      </Grid>
    </Grid>
  );
}

DeviceDashboard.propTypes = {
  data: PropTypes.array.isRequired,
  navigate: PropTypes.object.isRequired
};


function PolicyDashboard({ data, devices, navigate }) {
  const getTotalUsed = () => {
    let count = 0;
    data.forEach((d) => {
      if (devices.find((obj) => obj.policy_id === d._id)) count += 1;
    });
    return count;
  };

  const getTotalRules = () => {
    let count = 0;
    data.forEach((d) => {
      Object.keys(d.data.facts).forEach((f) => {
        count += Object.keys(d.data.facts[f]).length;
      });
    });
    return count;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} >
        <DashboardCardName name='Policies' link='/policies' navigate={navigate} />
      </Grid>
      <Grid item xs={12} >
        <Typography variant="h2" gutterBottom>
          {data.length}
        </Typography>
        <Typography variant="body1" gutterBottom>
          In use:
          {' '}
          {getTotalUsed()}
        </Typography>
      </Grid>
    </Grid>
  );
}

PolicyDashboard.propTypes = {
  data: PropTypes.array.isRequired,
  devices: PropTypes.array.isRequired,
  navigate: PropTypes.object.isRequired
};


function GatewayDashboard({ data, navigate }) {
  const getTotalConfigured = () => {
    let count = 0;
    data.forEach((d) => {
      count += d.ipv4 || d.ipv6 ? 1 : 0;
    });
    return count;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} >
        <DashboardCardName name='Gateways' link='/gateways' navigate={navigate} />
      </Grid>
      <Grid item xs={12} >
        <Typography variant="h2" gutterBottom>
          {data.length}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Configured:
          {' '}
          {getTotalConfigured()}
        </Typography>
      </Grid>
    </Grid>
  );
}

GatewayDashboard.propTypes = {
  data: PropTypes.array.isRequired,
  navigate: PropTypes.object.isRequired
};


function DashboardCardName({ name, link, navigate }) {
  return (
    <ButtonBase onClick={() => navigate(link)}>
      <Typography variant="h6" gutterBottom>
        {name}
      </Typography>
    </ButtonBase>
  );
}

DashboardCardName.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  navigate: PropTypes.object.isRequired
};
