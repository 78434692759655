import * as React from 'react';
import PropTypes from 'prop-types';
import CategoryPolicyRule from './Category';
import AppPolicyRule from './Application';
import TimestampFilter from './TimestampFilter';


export default function RuleComponent({ data }) {
  switch (data.getUIType()) {
  case 'Category':
    return <CategoryPolicyRule data={data} />;
  case 'App':
    return <AppPolicyRule data={data} />;
  case 'Time Filter':
    return <TimestampFilter data={data} />;
  default:
    return <></>;
  }
}

RuleComponent.propTypes = {
  data: PropTypes.object.isRequired
};
