import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { API } from '../api';
import { DragAndDropNetworkList } from './Common';


export default function CategoryPolicyRule({ data }) {
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    const asyncFn = async () => {
      const rv = await API.fetch('/app_category', () => {});
      if (rv) setCategories(rv.filter((e) => e.type === 'Category'));
    };
    asyncFn();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectCategory data={data} categories={categories} />
      </Grid>
      <DragAndDropNetworkList data={data} />
    </Grid>
  );
}

CategoryPolicyRule.propTypes = {
  data: PropTypes.object.isRequired
};


function SelectCategory({ data, categories }) {
  const [value, setValue] = React.useState(data.getCategory());

  const setPolicyType = (newValue) => {
    data.setCategory(newValue, categories[0] ? categories[0].type : '');
    setValue(data.getCategory());
  };

  return (
    <TextField
      fullWidth
      value={value}
      defaultValue={value}
      onChange={(event) => setPolicyType(event.target.value)}
      select
      label='Category'
    >
      {
        categories.map((e) => (
          <MenuItem
            key={e.fact.id}
            value={e.fact.id}
          >
            {e.fact.id}
          </MenuItem>
        ))
      }
    </TextField>
  );
}

SelectCategory.propTypes = {
  data: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired
};
