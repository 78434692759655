import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { experimentalStyled as styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { API } from './api';
import { NameField, DeleteTableItem, DescriptionField, SetIPAddr } from './CommonComp';
import { isValidIP } from './utils';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#E5E4E2',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary
}));


export default function MobilityGatewayView() {
  const apiRoute = '/gateway';
  const [gateways, setGateways] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  async function deleteGateway(idx) {
    await API.delete(`${apiRoute}/${gateways[idx]._id}`, idx, gateways, setGateways);
  }

  async function updateGateway(idx, update) {
    await API.update(`${apiRoute}/${gateways[idx]._id}`, update, idx, gateways, setGateways);
  }

  async function insertGateway(data) {
    await API.insert(apiRoute, data, gateways, setGateways);
  }

  React.useEffect(() => {
    const asyncFn = async () => { await API.fetch(apiRoute, setGateways); };
    asyncFn();
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: '90px' }}
    >
      <Grid container spacing={3} columns={16}>
        {
          gateways.length ? (
            gateways.map((item, index) => (
              <Grid item xs={8} key={index}>
                <Item>
                  <GatewayGrid item={item} index={index} deleteGateway={deleteGateway} updateGateway={updateGateway} />
                </Item>
              </Grid>
            ))
          ) :
            <Grid container justifyContent="center" style={{ marginTop: '90px' }}>
              <Typography variant="h5" gutterBottom>
                No gateways have been added yet.
              </Typography>
            </Grid>
        }
      </Grid>
      <Fab
        style={{ position: 'fixed', bottom: '21px', right: '15px' }}
        color='primary'
        aria-label='add'
        label='Add'
        onClick={() => setOpen(true) }
      >
        <AddIcon />
      </Fab>
      {
        open ? <SetGateway open={open} setOpen={setOpen} submit={insertGateway}/> : null
      }
    </Grid>
  );
}


function GatewayGrid({ item, index, deleteGateway, updateGateway }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6">
          { item.name }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle1">
          { item.description }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle2">
          IPv4:
          <Typography component="span">
            {` ${item.ipv4 ? item.ipv4 : ''}`}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle2">
          IPv6:
          <Typography component="span">
            {` ${item.ipv6  ? item.ipv6 : ''}`}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={1}>
        <RenderSettings row={item} index={index} submit={updateGateway} />
      </Grid>
      <Grid item xs={1}>
        <DeleteTableItem index={index} submit={deleteGateway} />
      </Grid>
    </Grid>
  );
}

GatewayGrid.defaultProps = {
  index: 0
};

GatewayGrid.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object.isRequired,
  deleteGateway: PropTypes.func.isRequired,
  updateGateway: PropTypes.func.isRequired
};


function RenderSettings({ row, index, submit }) {
  const [open, setOpen] = React.useState(false);

  const handleUpdate = async (update) => {
    setOpen(false);
    const {_id, ...rest} = update;
    await submit(index, rest);
  };

  return (
    <>
      <IconButton name="details" onClick={() => setOpen(true)}>
        <EditIcon />
      </IconButton>
      {
        open ? <SetGateway open={open} setOpen={setOpen} row={row} submit={handleUpdate}/> : null
      }
    </>
  );
}

RenderSettings.defaultProps = {
  index: 0
};

RenderSettings.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number,
  submit: PropTypes.func.isRequired
};


function SetGateway({ open, setOpen, row, submit }) {
  const [data, setData] = React.useState(row || {
    name: '',
    description: ''
  });

  const disableSaveBtn = () => {
    if (data.name.trim() === '') return true;
    if (data.ipv4 || data.ipv6) {
      let validIP = false;
      if (data.ipv4) validIP = !isValidIP(data.ipv4);
      if (data.ipv6) return validIP || !isValidIP(data.ipv6);
      return validIP;
    }
    if (row) return JSON.stringify(data) === JSON.stringify(row);
    return false;
  };

  const handleSaveBtn = async () => {
    setOpen(false);
    await submit(data);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="add-a-new-gateway-dialog"
        fullWidth
      >
        <DialogTitle id="add-new-gateway-dialog-title" onClose={() => setOpen(false)}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            { row ? 'Update The Gateway' : 'Add a New Gateway' }
            <IconButton aria-label="close" sx={{ ml: 'auto' }} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <NameField data={data} setData={setData} />
            </Grid>
            <Grid item sm={12}>
              <DescriptionField data={data} setData={setData} />
            </Grid>
            <Grid item sm={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <SetIPAddr type='ipv4' data={data} setData={setData} />
            </Grid>
            <Grid item xs={12}>
              <SetIPAddr type='ipv6' data={data} setData={setData} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={0 || disableSaveBtn()}
            onClick={handleSaveBtn}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SetGateway.defaultProps = {
  row: undefined
};

SetGateway.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  row: PropTypes.object,
  submit: PropTypes.func.isRequired
};

