import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import { experimentalStyled as styled } from '@mui/material/styles';
import { API } from './api';
import { DeleteTableItem } from './CommonComp';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#BDEDFF',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary
}));


export default function PoliciesView() {
  const apiRoute = '/policy';
  const [policies, setPolicies] = React.useState([]);
  const navigate = useNavigate();

  async function deletePolicy(idx) {
    await API.delete(`${apiRoute}/${policies[idx]._id}`, idx, policies, setPolicies);
  }

  React.useEffect(() => {
    const asyncFn = async () => {
      const rv = await API.fetch(apiRoute, setPolicies);
      setPolicies(rv);
    };
    asyncFn();
  }, [setPolicies]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: '90px' }}
    >
      <Grid container spacing={3} columns={16}>
        {
          policies.length ? (
            policies.map((item, index) => (
              <Grid item xs={8} key={index}>
                <Item>
                  <PolicyGrid data={item} index={index} deletePolicy={deletePolicy} navigate={navigate} />
                </Item>
              </Grid>
            ))
          ) :
            <Grid container justifyContent="center" style={{ marginTop: '90px' }}>
              <Typography variant="h5" gutterBottom>
                No policies have been added yet.
              </Typography>
            </Grid>
        }
      </Grid>
      <Fab
        style={{ position: 'fixed', bottom: '21px', right: '15px' }}
        color='primary'
        aria-label='add'
        label='Add'
        onClick={() => navigate('/policies/new-policy')}
      >
        <AddIcon />
      </Fab>
    </Grid>
  );
}


function PolicyGrid({ data, index, deletePolicy, navigate }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            { data.name }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="subtitle1">
            { data.description }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link
            component="button"
            variant="body2"
            onClick={() => navigate(`/policies/view/${data._id}`)}
          >
            Click here to view the policy in detail.
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={1}>
          <IconButton name="details" onClick={() => navigate(`/policies/edit/${data._id}`)}>
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <DeleteTableItem index={index} submit={deletePolicy} />
        </Grid>
      </Grid>
    </>
  );
}

PolicyGrid.defaultProps = {
  index: 0
};

PolicyGrid.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object.isRequired,
  deletePolicy: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired
};
