import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemButton from '@mui/material/ListItemButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { API } from './api';
import RuleComponent from './rule-components/Rule';
import RuleListObj from './rule-components/RuleListObj';
import { NameField, DescriptionField } from './CommonComp';
import { PolicyRule } from './PolicyRule';


export default function PolicyEditView() {
  const apiRoute = '/policy';
  const params = useParams();
  const [policy, setPolicy] = React.useState(null);
  const [selectedID, setSelectedID] = React.useState(null);

  if (params.id !== selectedID) setSelectedID(params.id);

  async function insertPolicy(data) {
    await API.insert(apiRoute, data, [], () => {});
  }

  async function updatePolicy(update) {
    if (update._id) delete update._id;
    await API.update(`${apiRoute}/${policy._id}`, update);
  }

  React.useEffect(() => {
    const asyncFn = async () => {
      if (params.id !== 'new-policy') await API.fetch(`${apiRoute}/${params.id}`, setPolicy);
    };
    asyncFn();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: '90px' }}
    >
      <SetPolicy dbData={policy} submit={policy ? updatePolicy : insertPolicy} />
    </Grid>
  );
}


function SetPolicy({ dbData, submit }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [rules, setRules] = React.useState([]);
  const [data, setData] = React.useState({
    name: '',
    description: '',
    data: {}
  });

  const disableSaveBtn = () => {
    if (data.name.trim() !== '') return false;
    return true;
  };

  const handleSaveBtn = async () => {
    const updatedData = {...data, data: PolicyRule.rulesToBundleData(rules)};
    setData(updatedData);
    await submit(updatedData);
    navigate('/policies');
  };

  const updateRules = (newValue, idx = -1) => {
    if (idx === -1) {
      const found = rules.find((e) => e.getCategory() === newValue.getCategory() && newValue.getType() !== 'global_timestamp_filters');
      if (!found) setRules([...rules, newValue]);
    } else {
      const tmpData = [...rules];
      tmpData[idx] = newValue;
      setRules(tmpData);
    }
  };

  React.useEffect(() => {
    if (dbData) {
      setData(dbData);
      setRules(PolicyRule.dbDataToRules(dbData));
    }
  }, [dbData]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h5" gutterBottom>
          { dbData ? 'Update The Policy' : 'Add a New Policy' }
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <NameField data={data} setData={setData} />
      </Grid>
      <Grid item sm={12}>
        <DescriptionField data={data} setData={setData} required={false} />
      </Grid>
      <Grid item sm={12}>
        <Button
          fullWidth
          variant="outlined"
          size="small"
          onClick={() => setOpen(true)}
        >
          Add a New Rule
        </Button>
      </Grid>
      <Grid item sm={12}>
        <PolicyRulesList rules={rules} updateRules={setRules} />
      </Grid>
      <Grid item sm={12}>
        <Button
          fullWidth
          disabled={disableSaveBtn()}
          onClick={handleSaveBtn}
          color="primary"
          variant="contained"
          size="small"
        >
          Save
        </Button>
      </Grid>
      <Grid item sm={12}>
        <Button
          fullWidth
          onClick={() => navigate('/policies')}
          color="primary"
          variant="outlined"
          size="small"
        >
          Cancel
        </Button>
      </Grid>
      {
        open ? <AddOrUpdateRule open={open} setOpen={setOpen} dbData={undefined} submit={updateRules}/> : null
      }
    </Grid>
  );
}

SetPolicy.defaultProps = {
  dbData: undefined
};

SetPolicy.propTypes = {
  dbData: PropTypes.object,
  submit: PropTypes.func.isRequired
};


function AddOrUpdateRule({ open, setOpen, dbData, submit }) {
  const [data, setData] = React.useState(dbData || new PolicyRule());
  const disableAddBtn = () => false;

  const handleSaveBtn = () => {
    setOpen(false);
    submit(data);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="add-a-new-policy-dialog"
        fullWidth
      >
        <DialogTitle id="add-new-policy-dialog-title" onClose={() => setOpen(false)}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            { dbData ? 'Update The Rule' : 'Add a New Rule' }
            <IconButton aria-label="close" sx={{ ml: 'auto' }} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectRule data={data} setData={setData} />
            </Grid>
            <Grid item xs={12}>
              <RuleComponent data={data} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={disableAddBtn()}
            onClick={handleSaveBtn}
            color="primary"
            autoFocus
          >
            {
              dbData ? 'Update' : 'Add'
            }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AddOrUpdateRule.defaultProps = {
  dbData: undefined
};

AddOrUpdateRule.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  dbData: PropTypes.object,
  submit: PropTypes.func.isRequired
};


function SelectRule({ data, setData }) {
  const [types] = React.useState(['App', 'Category', 'Time Filter']);

  return (
    <TextField
      fullWidth
      value={data.getUIType()}
      defaultValue={data.getUIType()}
      onChange={(event) => setData(new PolicyRule(event.target.value))}
      select
      label='Rule Type'
    >
      {
        types.map((t) => (
          <MenuItem
            key={t}
            value={t}
          >
            {`${t}`}
          </MenuItem>
        ))
      }
    </TextField>
  );
}

SelectRule.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired
};


function PolicyRulesList({ rules, updateRules }) {
  const [currIdx, setCurrIdx] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleDelete = (idx) => {
    const newData = [...rules];
    newData.splice(idx, 1);
    updateRules(newData);
  };

  const handleUpdateBtn = (idx) => {
    setCurrIdx(idx);
    setOpen(true);
  };

  const handleUpdate = (data) => {
    const newData = [...rules];
    newData[currIdx] = data;
    updateRules(newData);
  };

  return (
    <>
      <List style={{maxHeight: 280, overflow: 'auto', bgcolor: 'background.paper'}}>
        <Typography variant="subtitle2" gutterBottom>
          {
            rules.length > 0 ? 'Selected policy rules' : ''
          }
        </Typography>
        <Divider />
        {
          rules.map((d, idx) => (
            <ListItem key={idx}>
              <ListItemButton>
                <RuleListObj idx={idx} data={d} />
              </ListItemButton>
              <ListItemSecondaryAction>
                <IconButton name="details" onClick={() => handleUpdateBtn(idx)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => handleDelete(idx)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        }
      </List>
      {
        open ? <AddOrUpdateRule open={open} setOpen={setOpen} dbData={rules[currIdx]} submit={handleUpdate}/> : null
      }
    </>
  );
}

PolicyRulesList.propTypes = {
  rules: PropTypes.array.isRequired,
  updateRules: PropTypes.func.isRequired
};
