export const categoryList = {
  'Maps & Navigation':      'Maps & Navigation',
  'Movies & Video':         'Movies & Video',
  'Music & Audio':          'Music & Audio',
  'News & Magazines':       'News & Magazines',
  'Photos & Images':        'Photos & Images',
  Productivity:             'Productivity',
  'Social & Communication': 'Social & Communication'
};

export const appList = {
  'com.google.android.youtube':      'YouTube',
  'com.android.chrome':              'Chrome',
  'com.google.android.gm':           'Gmail',
  'com.duckduckgo.mobile.android':   'DuckDuckGo',
  'com.facebook.katana':             'Facebook',
  'com.linkedin.android':            'LinkedIn',
  'com.whatsapp':                    'WhatsApp',
  'com.google.android.calendar':     'Calendar',
  'com.accuweather.android':         'Weather',
  'com.microsoft.teams':             'Teams',
  'org.jitsi.meet':                  'Jitsi',
  'com.google.android.apps.tachyon': 'Google Meet'
};


export function getPolicyNetworkList() {
  return [
    { id: 'cell', type: 'CELL', content: '5G Network', weight: 2 },
    { id: 'wifi', type: 'WIFI', content: 'WiFi Network', weight: 1 }
  ];
}
