export function createAlertMsg(message, error) {
  if (error && error.response && ((error.response.status === 500) ||
        (error.response.status === 400) || (error.response.status === 401)))  {
    return `${message}: ${error.response.data.message}`;
  } if (error && error.request) {
    return `${message}: ${error}`;
  } if (error) {
    return `${message}: ${error.message}`;
  }
  return `${message}`;
}


export function verifyPlainPassword(password) {
  if (typeof password !== 'string') {
    return null;
  }
  // This regex code will validate password with:
  // 1. Minimum length is 6 and max length is 20.
  // 2. At least include a digit number.
  // 3. At least an upper case and a lower case letter.
  // 4. At least a special character.
  const regex = /^(?=\S{6,20}$)(?=.*?\d)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[^A-Za-z\s0-9])/;
  return password.match(regex);
}


export function deepCopy(obj) {
  if (obj) return JSON.parse(JSON.stringify(obj));
  return obj;
}


export function isValidIP(ip) {
  const ipv4Regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  const ipv6Regex = /^([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,7}:|^([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,5}(?::[0-9a-fA-F]{1,4}){1,2}$|^([0-9a-fA-F]{1,4}:){1,4}(?::[0-9a-fA-F]{1,4}){1,3}$|^([0-9a-fA-F]{1,4}:){1,3}(?::[0-9a-fA-F]{1,4}){1,4}$|^([0-9a-fA-F]{1,4}:){1,2}(?::[0-9a-fA-F]{1,4}){1,5}$|^[0-9a-fA-F]{1,4}:(?::[0-9a-fA-F]{1,4}){1,6}$|^:(?::[0-9a-fA-F]{1,4}){1,7}$|^::$/;
  return ipv4Regex.test(ip) || ipv6Regex.test(ip);
}
