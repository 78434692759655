import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { API } from './api';
import RuleListObj from './rule-components/RuleListObj';
import { NameField, DescriptionField } from './CommonComp';
import { PolicyRule } from './PolicyRule';


export default function PolicyView() {
  const apiRoute = '/policy';
  const params = useParams();
  const [policy, setPolicy] = React.useState(null);

  React.useEffect(() => {
    const asyncFn = async () => {
      await API.fetch(`${apiRoute}/${params.id}`, setPolicy);
    };
    asyncFn();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: '90px' }}
    >
      {
        policy ? <PolicyGridView dbData={policy} /> : null
      }
    </Grid>
  );
}


function PolicyGridView({ dbData }) {
  const navigate = useNavigate();
  const rules = PolicyRule.dbDataToRules(dbData);
  const [data, setData] = React.useState(dbData);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h5" gutterBottom>
          Policy View
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <NameField data={data} setData={setData} disabled={true} />
      </Grid>
      <Grid item sm={12}>
        <DescriptionField data={data} setData={setData} required={false} disabled={true} />
      </Grid>
      <Grid item sm={12}>
        <PolicyRulesList rules={rules} />
      </Grid>
      <Grid item sm={12}>
        <Button
          fullWidth
          onClick={() => navigate('/policies')}
          color="primary"
          variant="outlined"
          size="small"
        >
          Go Back
        </Button>
      </Grid>
    </Grid>
  );
}

PolicyGridView.defaultProps = {
  dbData: undefined
};

PolicyGridView.propTypes = {
  dbData: PropTypes.object
};


function PolicyRulesList({ rules }) {
  return (
    <List style={{maxHeight: 280, overflow: 'auto'}}>
      <Typography variant="subtitle2" gutterBottom>
        {
          rules.length > 0 ? 'Selected policy rules' : ''
        }
      </Typography>
      <Divider />
      {
        rules.map((d, idx) => (
          <ListItem key={idx}>
            <RuleListObj idx={idx} data={d} />
          </ListItem>
        ))
      }
    </List>
  );
}

PolicyRulesList.propTypes = {
  rules: PropTypes.array.isRequired
};
