import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SnackbarService from './SnackbarService';
import { UserContext } from './auth';
import { api } from './api';
import { verifyPlainPassword, createAlertMsg } from './utils';

const defaultTheme = createTheme();

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function SignIn() {
  const navigate = useNavigate();
  const { userSignedIn, userSignIn } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameErrorText, setUsernameErrorText] = useState('');
  const [signInError, setSignInError] = useState('');

  if (userSignedIn) navigate('/devices');

  async function SignInUser(request) {
    try {
      const rv = await api.post(`/user/${username}/sign-in`, request);
      if (rv.data.error) {
        setSignInError(rv.data.error);
        return null;
      }
      return rv.data;
    } catch (error) {
      SnackbarService.show(createAlertMsg('Error while signing in', error), 'error');
    }

    return null;
  }

  const handleSignIn = async (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
    if (!username && !password) {
      setSignInError('Please provide username and password');
      return;
    }
    setSignInError('');
    setUsernameErrorText('');
    if (username.length < 1 || !verifyPlainPassword(password)) {
      setUsernameErrorText('The username or password is incorrect');
      return;
    }

    const rv = await SignInUser({username, password});
    if (rv) {
      userSignIn(rv);
      navigate('/devices');
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh'}}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            {
              signInError ? (
                <Alert severity="error">
                  {signInError}
                </Alert>
              ) : null
            }
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSignIn} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email"
                name="username"
                autoComplete="username"
                autoFocus
                error={usernameErrorText.length !== 0}
                helperText={usernameErrorText.length ? usernameErrorText : ''}
                onChange={(event) => setUsername(event.target.value)}
                onBlur={(event) => setUsername((event.target.value).trim())}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={usernameErrorText.length !== 0}
                onChange={(event) => setPassword(event.target.value)}
                onBlur={(event) => setPassword((event.target.value).trim())}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
