import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import { getPolicyNetworkList } from '../constants';
import { DragAndDrop, NumberField } from '../CommonComp';


export function InterfaceWeight({ type, data }) {
  const [value, setValue] = React.useState(data.getInterfaceVal(type));

  const updateData = (newValue) => {
    setValue(newValue);
    data.setInterface(type, newValue);
  };

  return (
    <NumberField label={type === 'CELL' ? '5G Weight' : 'WiFi Weight'} data={value} setData={updateData} dkey={type} />
  );
}

InterfaceWeight.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
};


export function getNetworkInputList(dbData) {
  let result = getPolicyNetworkList();
  if (dbData) {
    const ifaces = dbData.ifaces.ifaces;
    result = result.filter((e) => {
      if (ifaces[e.type]) {
        e.weight = ifaces[e.type];
        return e;
      }
      return 0;
    });
    return result.sort((a, b) => (a.weight >= b.weight ? -1 : 1));
  }
  return result;
}


// TODO I should rename the component name.
export function DragAndDropNetworkList({ data }) {
  const [open, setOpen] = React.useState(false);
  const [inputList, setInputList] = React.useState(getNetworkInputList(data));

  const handleDragEnd = (reorderedList) => {
    reorderedList.forEach((element) => data.setInterface(element.type, element.weight));
  };

  const deleteItem = (index) => {
    data.delInterface(inputList[index].type);
    inputList.splice(index, 1);
    setInputList(JSON.parse(JSON.stringify(inputList)));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    const found = inputList.find((obj) => obj.id === value.id);
    if (!found) {
      const tmp = [...inputList, value].sort((a, b) => (a.weight >= b.weight ? -1 : 1));
      setInputList(tmp);
      tmp.forEach((element) => data.setInterface(element.type, element.weight));
    }
  };

  return (
    <>
      <Grid item sm={12}>
        <Button fullWidth variant="outlined" size="small" onClick={handleClickOpen} >
          Add Network
        </Button>
        <NetworkAddingDialog
          open={open}
          setOpen={setOpen}
          onClose={handleClose}
        />
      </Grid>
      {
        inputList && inputList.length ? (
          <>
            <Grid item sm={12}>
              Drag and drop the item to prioritize the network
            </Grid>
            <Grid item sm={12}>
              <DragAndDrop inputList={inputList} setInputList={setInputList} handleDragEndFn={handleDragEnd} deleteItem={deleteItem} />
            </Grid>
          </>
        ) : null
      }
    </>

  );
}

DragAndDropNetworkList.propTypes = {
  data: PropTypes.object.isRequired
};


function NetworkAddingDialog({ open, setOpen, onClose }) {
  const networkList = getPolicyNetworkList();

  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <List sx={{ pt: 0 }}>
        {networkList.map((d) => (
          <ListItem disableGutters key={d.id}>
            <ListItemButton onClick={() => onClose(d)}>
              <ListItemText primary={d.content} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

NetworkAddingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
