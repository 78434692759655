import * as React from 'react';
import PropTypes from 'prop-types';
import { appList, categoryList } from '../constants';


function getName(data) {
  const category = data.getCategory();
  if (appList[category]) return appList[category];
  if (categoryList[category]) return categoryList[category];
  return 'Time filter';
}


function showNetwork(ifaces) {
  if (ifaces.CELL && ifaces.WIFI) return `| 5G Weight: ${ifaces.CELL} | WiFi Weight: ${ifaces.WIFI}`;
  if (ifaces.CELL) return `| 5G Weight: ${ifaces.CELL}`;
  return `| WiFi Weight: ${ifaces.WIFI}`;
}


export default function RuleListObj({ idx, data }) {
  switch (data.getUIType()) {
  case 'Category':
    return <CategoryPolicyRuleListObj idx={idx} data={data} />;
  case 'App':
    return <AppPolicyRuleListObj idx={idx} data={data} />;
  case 'Time Filter':
    return <TimestampFilterListObj idx={idx} data={data} />;
  default:
    return <></>;
  }
}

RuleListObj.propTypes = {
  idx: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
};


function CategoryPolicyRuleListObj({ idx, data }) {
  const ifaces = data.getInterface().ifaces;
  return (
    <>
      {`${idx + 1}. ${getName(data)} ${showNetwork(ifaces)}`}
    </>
  );
}

CategoryPolicyRuleListObj.propTypes = {
  idx: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
};


function AppPolicyRuleListObj({ idx, data }) {
  const ifaces = data.getInterface().ifaces;
  return (
    <>
      {`${idx + 1}. ${getName(data)} ${showNetwork(ifaces)}`}
    </>
  );
}

AppPolicyRuleListObj.propTypes = {
  idx: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
};


function TimestampFilterListObj({ idx, data }) {
  const tf = data.getTimestampObject();
  const start = new Date(tf.start).toLocaleString('en-US');
  const end = new Date(tf.end).toLocaleString('en-US');
  return (
    <>
      {`${idx + 1}. ${getName(data)}  | Start: ${start} | End: ${end} | Netowk: ${tf.network === 'CELL' ? '5G' : tf.network}`}
    </>
  );
}

TimestampFilterListObj.propTypes = {
  idx: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
};
