import * as React from 'react';
import PropTypes from 'prop-types';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E5E4E2',
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));


export default function MuiTableHead({ columns }) {
  return (
    <TableHead>
      <TableRow>
        {
          columns.map((column) => (
            <StyledTableCell align="center" key={column}>
              {column}
            </StyledTableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
}

MuiTableHead.propTypes = {
  columns: PropTypes.array.isRequired
};
