import * as React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


export default function TimestampFilter({ data }) {
  const [startTime, setStartTime] = React.useState(data.getStartTime());
  const [endTime, setEndTime] = React.useState(data.getEndTime());
  const [network, setNetwork] = React.useState(data.getTimeNetwork());

  const handleStart = (newValue) => {
    data.setStartTime(newValue);
    setStartTime(newValue);
  };

  const handleEnd = (newValue) => {
    data.setEndTime(newValue);
    setEndTime(newValue);
  };

  const handleNetwork = (newValue) => {
    data.setTimeNetwork(newValue);
    setNetwork(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DateTimePicker
              label="Start"
              value={startTime}
              onChange={handleStart}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DateTimePicker
              label="End"
              value={endTime}
              onChange={handleEnd}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={network || ''}
          onChange={(event) => handleNetwork(event.target.value)}
          select
          label='Network'
        >
          {
            ['WIFI', 'CELL'].map((t) => (
              <MenuItem
                key={t}
                value={t}
              >
                {t}
              </MenuItem>
            ))
          }
        </TextField>
      </Grid>
    </Grid>
  );
}

TimestampFilter.propTypes = {
  data: PropTypes.object.isRequired
};
