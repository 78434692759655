import * as React from 'react';
import PropTypes from 'prop-types';
import { experimentalStyled as styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { API } from './api';
import { NameField, DeleteTableItem, DescriptionField } from './CommonComp';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#BDEDFF',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary
}));


export default function UsersView({ userData }) {
  const apiRoute = '/user';
  const [users, setUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  async function insertUser(data) {
    await API.insert(apiRoute, {...data, organization: userData.username}, users, setUsers);
  }

  async function updateUser(idx, update) {
    await API.update(`${apiRoute}/${users[idx]._id}`, update, idx, users, setUsers);
  }

  async function deleteUser(idx) {
    await API.delete(`${apiRoute}/${users[idx]._id}`, idx, users, setUsers);
  }

  React.useEffect(() => {
    const asyncFn = async () => { await API.fetch(apiRoute, setUsers); };
    asyncFn();
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: '90px' }}
    >
      <Grid container spacing={3}>
        {
          users.length ? (
            users.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Item>
                  <UserGrid item={item} index={index} deleteUser={deleteUser} updateUser={updateUser} />
                </Item>
              </Grid>
            ))
          ) :
            <Grid container justifyContent="center" style={{ marginTop: '90px' }}>
              <Typography variant="h5" gutterBottom>
                No users have been added yet.
              </Typography>
            </Grid>
        }
      </Grid>
      <Fab
        style={{ position: 'fixed', bottom: '21px', right: '15px' }}
        color='primary'
        aria-label='add'
        label='Add'
        onClick={() => setOpen(true) }
      >
        <AddIcon />
      </Fab>
      {
        open ? <SetUser open={open} setOpen={setOpen} submit={insertUser}/> : null
      }
    </Grid>
  );
}

UsersView.defaultProps = {
  userData: null
};

UsersView.propTypes = {
  userData: PropTypes.object
};


function UserGrid({ item, index, deleteUser, updateUser }) {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Avatar>
          {
            `${item.username.charAt(0)}`
          }
        </Avatar>
      </Grid>
      <Grid item>
        <Typography gutterBottom variant="h6">
          { item.name }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle1">
          { `Email: ${item.username}` }
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          { `Role: ${item.role}` }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle1">
          { item.description }
        </Typography>
      </Grid>
      {
        item.password ? (
          <Grid item xs={12}>
            <Typography gutterBottom variant="subtitle1">
              { `The password is shown one-time: ${item.password}` }
            </Typography>
          </Grid>
        ) : null
      }
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item>
        <RenderSettings row={item} index={index} submit={updateUser} />
      </Grid>
      <Grid item>
        <DeleteTableItem index={index} submit={deleteUser} />
      </Grid>
    </Grid>
  );
}

UserGrid.defaultProps = {
  index: 0
};

UserGrid.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};


function RenderSettings({ row, index, submit }) {
  const [open, setOpen] = React.useState(false);

  const handleUpdate = async (update) => {
    setOpen(false);
    const {_id, ...rest} = update;
    await submit(index, rest);
  };

  return (
    <>
      <IconButton name="details" onClick={() => setOpen(true)}>
        <EditIcon />
      </IconButton>
      {
        open ? <SetUser open={open} setOpen={setOpen} row={row} submit={handleUpdate}/> : null
      }
    </>
  );
}

RenderSettings.defaultProps = {
  index: 0
};

RenderSettings.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number,
  submit: PropTypes.func.isRequired
};


function SetUser({ open, setOpen, row, submit }) {
  const [data, setData] = React.useState(row || {
    username: '',
    name: '',
    description: '',
    role: 'standard'
  });

  const disableSaveBtn = () => {
    if (data.name.trim() === '') return true;
    if (row) return JSON.stringify(data) === JSON.stringify(row);
    return false;
  };

  const handleSaveBtn = async () => {
    setOpen(false);
    await submit(data);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="add-a-new-user-dialog"
        fullWidth
      >
        <DialogTitle id="add-new-user-dialog-title" onClose={() => setOpen(false)}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            { row ? 'Update The User' : 'Add a New User' }
            <IconButton aria-label="close" sx={{ ml: 'auto' }} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Username data={data} setData={setData} />
            </Grid>
            <Grid item sm={12}>
              <NameField data={data} setData={setData} />
            </Grid>
            <Grid item sm={12}>
              <DescriptionField data={data} setData={setData} />
            </Grid>
            <Grid item xs={12}>
              <UserRole data={data} setData={setData} />
            </Grid>
            <Grid item sm={12}>
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={0 || disableSaveBtn()}
            onClick={handleSaveBtn}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SetUser.defaultProps = {
  row: undefined
};

SetUser.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  row: PropTypes.object,
  submit: PropTypes.func.isRequired
};


function Username({ data, setData }) {
  return (
    <TextField
      fullWidth
      type="string"
      name="User Name"
      label="Username (email) *"
      variant="filled"
      value={data.username}
      onChange={(event) => {
        setData({...data, username: String(event.target.value)});
      }}
    />
  );
}

Username.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired
};


function UserRole({ data, setData }) {
  const [roles] = React.useState(['standard', 'admin']);

  return (
    <FormControl fullWidth>
      <InputLabel id="user-role">
        User Role
      </InputLabel>
      <Select
        labelId="user-role-label"
        id="UserRole"
        value={data.role || ''}
        defaultValue={data.role}
        label="Select Role"
        onChange={(event) => setData({...data, role: String(event.target.value).trim()})}
      >
        {
          roles.map((t) => (
            <MenuItem
              key={t}
              value={t}
            >
              {`${t}`}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

UserRole.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired
};
