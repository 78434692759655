import dayjs from 'dayjs';


const UITypeToPackageName = {
  App: 'custom_package_policies',
  Application: 'custom_package_policies',
  Category: 'category_policies',
  'Time Filter': 'global_timestamp_filters'
};


function getDefaultCategory(type) {
  switch (type) {
  case 'category_policies':
    return 'Productivity';
  case 'custom_package_policies':
    return 'com.android.chrome';
  case 'global_timestamp_filters':
    return 'Timestamp filters';
  default:
    return 'Productivity';
  }
}


export class PolicyRule {
  type;
  category;
  ifaces;
  timeFilter;

  constructor(type = 'category_policies', category = null, ifaces = {ifaces: {CELL: 1, WIFI: 2}}) {
    this.type = UITypeToPackageName[type] ? UITypeToPackageName[type] : type;
    this.category = category || getDefaultCategory(this.type);
    this.ifaces = ifaces;
    if (this.type === 'global_timestamp_filters') {
      this.timeFilter = {
        start: dayjs(),
        end: dayjs(),
        network: 'WIFI'
      };
    }
  }

  setCategory(category, type) {
    this.type = UITypeToPackageName[type];
    this.category = category;
  }

  getCategory() {
    return this.category;
  }

  setInterface(iface, value) {
    if (this.type !== 'global_timestamp_filters') this.ifaces.ifaces[iface] = value;
  }

  delInterface(iface) {
    if (this.type !== 'global_timestamp_filters') delete this.ifaces.ifaces[iface];
  }

  getInterfaceVal(type) {
    if (this.type !== 'global_timestamp_filters') return this.ifaces.ifaces[type];
    return null;
  }

  getInterface() {
    return this.ifaces;
  }

  getType() {
    return this.type;
  }

  getUIType() {
    const packageNameToUIType = {
      custom_package_policies: 'App',
      category_policies: 'Category',
      global_timestamp_filters: 'Time Filter'
    };
    return packageNameToUIType[this.type];
  }

  isGlobalTimestamp() {
    return this.type === 'global_timestamp_filters';
  }

  isCategory() {
    return this.type === 'category_policies';
  }

  isCustom() {
    return this.type === 'custom_package_policies';
  }

  setStartTime(value) {
    if (this.type === 'global_timestamp_filters') this.timeFilter.start = value;
  }

  getStartTime() {
    if (this.type === 'global_timestamp_filters') return this.timeFilter.start;
    return null;
  }

  setEndTime(value) {
    if (this.type === 'global_timestamp_filters') this.timeFilter.end = value;
  }

  getEndTime() {
    if (this.type === 'global_timestamp_filters') return this.timeFilter.end;
    return null;
  }

  setTimeNetwork(value) {
    if (this.type === 'global_timestamp_filters') this.timeFilter.network = value;
  }

  getTimeNetwork() {
    if (this.type === 'global_timestamp_filters') return this.timeFilter.network;
    return null;
  }

  getTimestampObject() {
    return {
      start: this.timeFilter.start.valueOf(),
      end: this.timeFilter.end.valueOf(),
      network: this.timeFilter.network
    };
  }

  static rulesToBundleData(rules) {
    const data = {
      category_policies: {},
      custom_package_policies: {},
      global_timestamp_filters: []
    };
    rules.forEach((e) => {
      if (e.getType() === 'global_timestamp_filters') {
        data[e.getType()].push(e.getTimestampObject());
      } else {
        data[e.getType()][e.getCategory()] = e.getInterface();
      }
    });
    return {facts: data};
  }

  static dbDataToRules(dbData) {
    /**
      data: {
        facts: {
          category_policies: {},
          custom_package_policies: {},
          global_timestamp_filters: [
            {
              start: <UTC milliseconds>,
              end: <UTC milliseconds>,
              network: <WIFI or CELL>
            },
            ...
          ]
        }
      }
    */
    const result = [];
    if (dbData && dbData.data.facts) {
      const facts = dbData.data.facts;
      Object.keys(facts).map((ruleType) => {
        if (ruleType === 'global_timestamp_filters') {
          const timeData = facts[ruleType];
          timeData.forEach((e) => {
            const data = new PolicyRule('global_timestamp_filters', '', {});
            data.setStartTime(dayjs(e.start));
            data.setEndTime(dayjs(e.end));
            data.setTimeNetwork(e.network);
            result.push(data);
          });
        } else {
          const ruleCategory = facts[ruleType];
          Object.keys(ruleCategory).map((categoryType) => {
            const ifaceData = ruleCategory[categoryType];
            result.push(new PolicyRule(ruleType, categoryType, ifaceData));
          });
        }
      });
    }
    return result;
  }
}
