import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const SnackbarComponent = ({ open, handleClose, message, severity, variant }) => (
  <Snackbar open={open} autoHideDuration={7000} onClose={handleClose}>
    <MuiAlert onClose={handleClose} severity={severity} variant={variant} sx={{ width: '100%' }}>
      {message}
    </MuiAlert>
  </Snackbar>
);

SnackbarComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired
};


const SnackbarService = () => {
  let snackbarRoot = document.getElementById('snackbar-root');
  if (!snackbarRoot) {
    snackbarRoot = document.createElement('div');
    snackbarRoot.id = 'snackbar-root';
    document.body.appendChild(snackbarRoot);
  }

  const closeSnackbar = (root) => {
    root.unmount(snackbarRoot);
  };

  const root = createRoot(snackbarRoot);
  const show = (message, severity, variant = 'standard') => {
    root.render(
      <SnackbarComponent
        open={true}
        handleClose={() => closeSnackbar(root)}
        message={message}
        severity={severity}
        variant={variant}
      />
    );
  };

  return { show };
};

export default SnackbarService();

