import * as React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { UserContext, getUserData } from './auth';
import MenuAppBar from './MenuAppBar';
import DashboardView from './Dashboard';
import DevicesView from './Devices';
import PoliciesView from './Policies';
import PolicyEditView from './PolicyEdit';
import MobilityGatewayView from './MobilityGateway';
import UsersView from './Users';
import AppsAndCategoriesView from './AppsAndCategories';
import PolicyView from './PolicyView';
import SignIn from './SignIn';


function NotFoundPage() {
  return (
    <div>
      Page Not Found
    </div>
  );
}


export default function App() {
  const { userSignedIn } = React.useContext(UserContext);

  return (
    <Container>
      {
        userSignedIn ? <MenuAppBar userData={userSignedIn ? getUserData() : null} /> : null
      }
      <Routes>
        <Route exact path="/"
          element={
            <PrivateRoute>
              <DevicesView />
            </PrivateRoute>
          } />
        <Route exact path="/signin" element={<SignIn/>} />
        <Route exact path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardView />
            </PrivateRoute>
          } />
        <Route exact path="/devices"
          element={
            <PrivateRoute>
              <DevicesView />
            </PrivateRoute>
          } />
        <Route exact path="/policies">
          <Route exact path=""
            element={
              <PrivateRoute>
                <PoliciesView />
              </PrivateRoute>
            }
          />
          <Route exact path=":id"
            element={
              <PrivateRoute>
                <PolicyEditView />
              </PrivateRoute>
            }
          />
          <Route exact path="edit/:id"
            element={
              <PrivateRoute>
                <PolicyEditView />
              </PrivateRoute>
            }
          />
          <Route exact path="view/:id"
            element={
              <PrivateRoute>
                <PolicyView />
              </PrivateRoute>
            }
          />
        </Route>
        <Route exact path="/mobilitygateway"
          element={
            <PrivateRoute>
              <MobilityGatewayView />
            </PrivateRoute>
          } />
        <Route exact path="/users"
          element={
            <PrivateRoute>
              <UsersView userData={userSignedIn ? getUserData() : null} />
            </PrivateRoute>
          } />
        <Route exact path="/applications"
          element={
            <PrivateRoute>
              <AppsAndCategoriesView />
            </PrivateRoute>
          } />
        <Route exact element={<NotFoundPage/>} />
      </Routes>
    </Container>
  );
}


const PrivateRoute = ({ children }) => {
  const { userSignedIn } = React.useContext(UserContext);
  return userSignedIn ? <>
    {children}
  </> : <Navigate to="/signin" />;
};

PrivateRoute.defaultProps = {
  children: undefined
};

PrivateRoute.propTypes = {
  children: PropTypes.object
};
