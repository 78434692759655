import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogTitle from '@mui/material/DialogTitle';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Zoom from '@mui/material/Zoom';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { isValidIP } from './utils';


export function ScrollTop() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10
  });

  return (
    <Zoom in={trigger}>
      <div
        onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}
        role="presentation"
        style={{ position: 'fixed', bottom: '21px', right: '15px' }}
      >
        <Fab color="primary" size="medium" aria-label="scroll back to top">
          <UpIcon />
        </Fab>
      </div>
    </Zoom>
  );
}


export function DeleteTableItem({ index, submit }) {
  const [open, setOpen] = React.useState(false);

  const handleDelete = () => {
    setOpen(false);
    submit(index);
  };

  return (
    <>
      <IconButton name="details" onClick={() => setOpen(true)}>
        <DeleteOutlineIcon />
      </IconButton>
      {
        open ? (
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="delete-table-item-dialog"
            fullWidth
          >
            <DialogTitle id="delete-table-item-dialog-title" onClose={() => setOpen(false)}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                Delete The Item
                <IconButton aria-label="close" sx={{ ml: 'auto' }} onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
              <Typography variant="subtitle1" gutterBottom>
                Are you sure you want to delete the item?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                No
              </Button>
              <Button
                onClick={handleDelete}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        ) : null
      }
    </>
  );
}

DeleteTableItem.defaultProps = {
  index: 0
};

DeleteTableItem.propTypes = {
  index: PropTypes.number,
  submit: PropTypes.func.isRequired
};


// NOTE: The data object should have a 'description' field. Otherwise, it will create it.
export function DescriptionField({ data, setData, required, disabled }) {
  const [error, setError] = React.useState(false);

  const setValue = (event) => {
    const val = event.target.value;
    setData({...data, description: val});
    if (val.trim().length > 0) setError(false);
    else if (required) setError(true);
  };

  return (
    <TextField
      disabled={disabled}
      required={required}
      fullWidth
      multiline={true}
      rows={2}
      type="string"
      name="Description"
      label="Description"
      value={data.description}
      error={error}
      onChange={setValue}
      onBlur={(event) => {
        setData({...data, description: event.target.value.trim()});
      }}
    />
  );
}

DescriptionField.defaultProps = {
  required: false,
  disabled: false
};

DescriptionField.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool
};


// NOTE: The data object should have a 'name' field. Otherwise, it will create it.
export function NameField({ data, setData, disabled }) {
  const [error, setError] = React.useState(false);

  const setValue = (event) => {
    const val = event.target.value;
    setData({...data, name: val});
    if (val.trim().length > 0) setError(false);
    else setError(true);
  };

  return (
    <TextField
      disabled={disabled}
      fullWidth
      type="string"
      name="Name"
      label="Name *"
      value={data.name}
      error={error}
      onChange={setValue}
      onBlur={(event) => {
        setData({...data, name: event.target.value.trim()});
      }}
    />
  );
}

NameField.defaultProps = {
  disabled: false
};

NameField.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};


export function NumberField({ label, data, setData }) {
  const [error, setError] = React.useState(false);

  const setValue = (event) => {
    setData(Number(event.target.value));
    if (event.target.value.trim().length > 0) setError(false);
    else setError(true);
  };

  return (
    <TextField
      fullWidth
      type="number"
      name={label}
      label={label}
      variant="filled"
      inputProps={{ step: 0.1 }}
      value={!data ? '' : data}
      error={error}
      onChange={setValue}
    />
  );
}

NumberField.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.number.isRequired,
  setData: PropTypes.func.isRequired
};


function DraggableListItem({ data, index, deleteItem }) {
  return (
    <Draggable key={data.id} draggableId={data.id} index={index}>
      {(provided) => (
        <>
          <ListItem ref={provided.innerRef} {...provided.draggableProps}>
            <ListItemIcon {...provided.dragHandleProps}>
              <DragHandleIcon />
            </ListItemIcon>
            <ListItemText primary={data.content} />
            <IconButton name="details" onClick={() => deleteItem(index)}>
              <DeleteOutlineIcon />
            </IconButton>
          </ListItem>
          <Divider />
        </>
      )}
    </Draggable>
  );
}

DraggableListItem.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  deleteItem: PropTypes.func.isRequired
};


function DroppableList({ inputList, deleteItem }) {
  return (
    <Droppable droppableId="droppable">
      {(provided) => (
        <List ref={provided.innerRef} {...provided.droppableProps}>
          {inputList.map((data, index) => (
            <DraggableListItem key={data.id} data={data} index={index} deleteItem={deleteItem} />
          ))}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  );
}

DroppableList.propTypes = {
  inputList: PropTypes.array.isRequired,
  deleteItem: PropTypes.func.isRequired
};


export function DragAndDrop({ inputList, setInputList, handleDragEndFn, deleteItem }) {
  const reorder = (list, startIndex, endIndex) => {
    const result = JSON.parse(JSON.stringify(list));
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEnd = (result) => {
    const rv = reorder(inputList, result.source.index, result.destination.index);
    // The top item should have the highest weight.
    let length = rv.length;
    rv.forEach((e) => {
      e.weight = length;
      length -= 1;
    });
    setInputList(rv);
    if (handleDragEndFn) handleDragEndFn([...rv], result);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <DroppableList inputList={inputList} deleteItem={deleteItem} />
    </DragDropContext>
  );
}

DragAndDrop.defaultProps = {
  handleDragEndFn: undefined
};

DragAndDrop.propTypes = {
  inputList: PropTypes.array.isRequired,
  setInputList: PropTypes.func.isRequired,
  handleDragEndFn: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired
};


export function SetIPAddr({ type, data, setData }) {
  const ipFormat = {ipv4: 'IPv4', ipv6: 'IPv6'};
  return (
    <TextField
      fullWidth
      type="string"
      name={`${ipFormat[type]} Address`}
      label={`${ipFormat[type]} Address`}
      variant="outlined"
      error={data[type] ? !isValidIP(data[type]) : false}
      value={data[type] || ''}
      onChange={(event) => {
        setData({...data, [type]: String(event.target.value)});
      }}
    />
  );
}

SetIPAddr.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired
};
