import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import SnackbarService from './SnackbarService';
import { getAuthHeader } from './auth';
import { api } from './api';
import { verifyPlainPassword, createAlertMsg } from './utils';


export default function ChangePassword(props) {
  const { open, setOpen, username } = props;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorText, setErrorText] = useState('');

  const disableChangeBtn = () => {
    if (!currentPassword || !newPassword) return true;
    return false;
  };

  const handleNewPassword = (event) => {
    setErrorText('');
    setNewPassword(event.target.value);
  };

  const handleChangeBtn = async () => {
    if (!verifyPlainPassword(newPassword)) {
      setErrorText(`
        Password must be:
        at least 6 characters, include a digit number,
        an upper case and a lower case letters, and a special character.
      `);
      return true;
    }

    try {
      const body = {old_password: currentPassword, new_password: newPassword};
      await api.post(`/user/${username}/change-passwd`, body, getAuthHeader());
    } catch (error) {
      SnackbarService.show(createAlertMsg('Error while changing password:', error), 'error');
    }

    setOpen(false);
    return true;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="change-password-dialog"
        fullWidth
      >
        <DialogTitle id="change-password-title" onClose={() => setOpen(false)}>
          Change Password
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            required
            type="password"
            margin="dense"
            variant="filled"
            id="current_password"
            value={currentPassword || ''}
            label="Current Password"
            onChange={(event) => setCurrentPassword(event.target.value)}
            onBlur={(event) => setCurrentPassword((event.target.value).trim())}
          />
          <br />
          <br />
          <TextField
            fullWidth
            required
            type="password"
            margin="dense"
            variant="filled"
            id="new_password"
            value={newPassword || ''}
            label="New Password"
            error={errorText.length !== 0}
            helperText={errorText.length ? errorText : ''}
            onChange={handleNewPassword}
            onBlur={(event) => setNewPassword((event.target.value).trim())}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={0 || disableChangeBtn()}
            onClick={handleChangeBtn}
            color="primary"
            autoFocus
          >
            Change
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ChangePassword.defaultProps = {
  username: ''
};

ChangePassword.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  username: PropTypes.string
};
